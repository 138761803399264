import styled from 'styled-components';

export const FullScreenSpace = styled.section`
  position: absolute;
  pointer-events: none;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  & > * {
    position: relative;
    pointer-events: auto;
  }
`;
export const ControlPanel = styled(FullScreenSpace)`
  z-index: 99;
  height: auto;
  justify-content: left;
`;
export const AppSection = styled.section`
  text-align: center;
`;
