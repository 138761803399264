import styled from 'styled-components';

export const InputFieldsGroup = styled.section`
  flex-direction: column;
  display: flex;
`;
export const PersonaNameInInventoryLabel = styled.label`
  margin-right: 4px;
`;
export const CharacterLevelSection = styled.section`
  display: flex;
`;
export const CharacterLevelInput = styled.input`
  flex: 1 1 auto;
  width: 1px;
  margin-left: 5px;
`;
export const PersonaInventorySection = styled.section`
  flex: 0 1 auto;
  margin-left: 50px;
`;
