// eslint-disable-next-line
import ReverseFusionWorker from "worker-loader!./worker";
import { WorkerPayload, WorkerResult } from './workerInterface.entity';

import { PersonaInventory } from '../../persona3fesReverseFusion.entity';
import { fusionForestEntity, searchDepthEntity } from '../../state/fusionTree.state';

export let workerInstance: ReverseFusionWorker;

const startThread = () => {
  workerInstance = new ReverseFusionWorker();

  workerInstance.onmessage = function ({ data }: MessageEvent<WorkerResult>) {
    fusionForestEntity.set(data.fusionForest);
  };
	
  workerInstance.onerror = function (error: unknown) {
    console.error(error);
    workerInstance.terminate();
    setTimeout(startThread, 100);
  };
	
};

export const sendPersonaInventoryToWorkerThread = (data: PersonaInventory, characterLevel = 99) =>
  workerInstance.postMessage({ personaInventory: data, characterLevel, maxSearchDepth: searchDepthEntity.get() } as WorkerPayload);

startThread();