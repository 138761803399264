import {useEffect, useMemo, useState} from 'react';
import {personaList} from '../../persona3fes';
import {PersonaName} from '../../persona3fes.entity';
import {characterLevelEntity, maxSearchDepth, searchDepthEntity, setSearchDepthOrBounds} from '../../state/fusionTree.state';
import {addPersonaToInventory, personaInventoryEntity, removePersonaFromInventory} from '../../state/personaInventory.state';
import {sendPersonaInventoryToWorkerThread} from '../../workers/reverseFusion/workerInterface';
import {PersonaNameInInventoryLabel, PersonaInventorySection, InputFieldsGroup, CharacterLevelSection, CharacterLevelInput} from './PersonaInventoryListStyledComponents';

export const PersonaInventoryList = () => {
  const personaInventoryList = personaInventoryEntity.use();
  const characterLevel = characterLevelEntity.use();
  const searchDepth = searchDepthEntity.use();

  const personaNamesInInventory = useMemo(() =>
    Object.keys(personaList)
      .filter(persona => !personaInventoryList.includes(persona as PersonaName))
      .sort(),
    [personaInventoryList]
  );
  const personaNamesDataList = useMemo(() =>
    <datalist id="all-persona-names">{
      personaNamesInInventory
        .map(personaName =>
          <option key={personaName} value={personaName}>
            {personaName}
          </option>
        )
    }</datalist>,
    [personaNamesInInventory]
  );
  const personaInventoryDisplayList = useMemo(() =>
    personaInventoryList.map(persona => (
      <section key={persona}>
        <PersonaNameInInventoryLabel>{persona}</PersonaNameInInventoryLabel>
        <button onClick={() => removePersonaFromInventory(persona)}>Remove</button>
      </section>
    )),
    [personaInventoryList]
  );

  const [selectedPersona, setSelectedPersona] = useState<string>(personaNamesInInventory[0]);

  useEffect(() => {
    setSelectedPersona(personaNamesInInventory[0]);
  }, [personaNamesInInventory]);

  useEffect(
    () => {
      if (searchDepth > maxSearchDepth()) setSearchDepthOrBounds(searchDepth);
    },
    [searchDepth, personaInventoryList.length]
  );

  return <PersonaInventorySection>
    <InputFieldsGroup>
      <CharacterLevelSection>
        Character Level: <CharacterLevelInput type="number" min={0} max={99} name="character-lvl-selector" id="character-lvl-selector" value={characterLevel} onChange={(e) => characterLevelEntity.set(Number(e.target.value))} />
      </CharacterLevelSection>
      <CharacterLevelSection>
        Search depth: {searchDepth} <CharacterLevelInput type="range" min={1} max={maxSearchDepth()} name="character-lvl-selector" id="character-lvl-selector" value={searchDepth} onChange={(e) => setSearchDepthOrBounds(Number(e.target.value))} />
      </CharacterLevelSection>
      <section>
        <input type="text" list="all-persona-names" name="persona-selector" id="persona-selector" value={selectedPersona} onChange={(e) => setSelectedPersona(e.target.value)} />
        {personaNamesDataList}
        <button disabled={personaInventoryList.length === 12 || !personaNamesInInventory.includes(selectedPersona)} onClick={() => addPersonaToInventory(selectedPersona as PersonaName)}>Add</button>
        <button disabled={personaInventoryList.length < 2} onClick={() => {
          sendPersonaInventoryToWorkerThread(
            Object.fromEntries(
              personaInventoryList.map(
                (personaName) => [
                  personaName,
                  {
                    ...personaList[personaName]
                  }
                ]
              )),
            characterLevel
          );
        }}>Fuse</button>
      </section>
    </InputFieldsGroup>
    <article>
      {personaInventoryDisplayList}
    </article>
  </PersonaInventorySection>;
};
