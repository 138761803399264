import {useEffect, useRef, useState} from 'react';
import {PersonaAttributes} from '../../persona3fes.entity';
import {PersonaNodeArea, PersonaNodeHeader, SkillDetails, SkillTable} from './FusionDiagramStyledComponents';

export const FusionDiagramNode = ({persona}: {persona: PersonaAttributes;}) => {
  const [isOpen, setIsOpen] = useState(false);
  const skillsTableRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (skillsTableRef.current) {
      if (isOpen) {
        skillsTableRef.current.style.maxHeight = skillsTableRef.current.scrollHeight + 'px';
      } else {
        skillsTableRef.current.style.maxHeight = '0px';
      }
    }
  }, [isOpen, skillsTableRef]);

  return (
    <PersonaNodeArea onClick={() => setIsOpen(!isOpen)}>
      <PersonaNodeHeader>
        <div>{persona.name}</div>
        <div>{persona.level}</div>
      </PersonaNodeHeader>
      <SkillDetails open isOpen={isOpen}>
        <summary onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}>
          Skills
        </summary>
        <SkillTable ref={skillsTableRef}>
          <table>
            <thead>
              <tr>
                <th>Skill Name</th>
                <th>Level</th>
              </tr>
            </thead>
            <tbody>
              {persona.skills?.map(({skill, level}) => (
                <tr key={skill}>
                  <td>{skill}</td>
                  <td>{level || persona.level}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </SkillTable>
      </SkillDetails>
    </PersonaNodeArea>
  );
};
