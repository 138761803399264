import {isFusedPersona, PersonaAttributes} from '../../persona3fes.entity';
import {
  Node,
  Edge
} from 'react-flow-renderer';
import {graphlib, layout} from 'dagre';
import {FusionDiagramNode} from './FusionDiagramNode';

const nodeWidth = 172;
const nodeHeight = 36;

export const createPrettyGraph = (nodes: Node[], edges: Edge[]) => {
  const dag = new graphlib.Graph();
  dag.setDefaultEdgeLabel(() => ({}));

  dag.setGraph({ rankdir: "TB" });

  nodes.forEach((node) => {
    dag.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dag.setEdge(edge.source, edge.target);
  });

  layout(dag);

  nodes.forEach((node) => {
    const nodeWithPosition = dag.node(node.id);

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };
  });

  return [nodes, edges] as const;
}

export const compileNodes = (persona: PersonaAttributes, nodes: Node[] = [], edges: Edge[] = [], depth = 0): [Node[], Edge[]] => {
  const currentPersonaNode = {
    data: {
      label: <FusionDiagramNode persona={persona}></FusionDiagramNode>
    },
    type: depth === 0
      ? 'input'
      : undefined,
    id: persona.id,
    position: {x: 0, y: 0}
  };
  nodes = [...nodes, currentPersonaNode];

  if(isFusedPersona(persona)) {
    edges = [
      ...edges,
      {
        id: `${persona.id}${persona.persona1.id}`,
        source: persona.id,
        target: persona.persona1.id
      },
      {
        id: `${persona.id}${persona.persona2.id}`,
        source: persona.id,
        target: persona.persona2.id
      },
    ];

    return compileNodes(
      persona.persona2,
      ...compileNodes(
        persona.persona1,
        nodes,
        edges,
        depth + 1
      ),
      depth + 1
    );
  }

  currentPersonaNode.type = 'output';

  return [nodes, edges];
};
