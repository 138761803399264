import ReactFlow, {
  Controls,
  Background, useNodesState, useEdgesState
} from 'react-flow-renderer';
import {fusionForestEntity, selectedFusionTreeEntity, selectFusionTree} from "../../state/fusionTree.state";
import {PersonaName} from "../../persona3fes.entity";
import {compileNodes, createPrettyGraph} from './treeUtils';
import {useEffect} from 'react';

import styled from 'styled-components';

const FusionDiagramFigure = styled.figure`
  flex: 1 1 auto;
  margin: 0;
`;

const FusionDiagramCaption = styled.figcaption`
  visibility: hidden;
`;

export const FusionDiagram = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  const fusionForest = fusionForestEntity.use();
  const selectedFusionTree = selectedFusionTreeEntity.use();

  useEffect(() => {
    const fusionResultPersonaNames = Object.keys(fusionForest) as PersonaName[];
    if(!selectedFusionTree || !fusionResultPersonaNames.includes(selectedFusionTree))
      selectFusionTree(fusionResultPersonaNames[0]);
    
    if (selectedFusionTree) {
      const [nodes, edges] = createPrettyGraph(
        ...compileNodes(fusionForest[selectedFusionTree]!)
      );
      setNodes(nodes);
      setEdges(edges);
    }
  }, [fusionForest, selectedFusionTree, setNodes, setEdges]);

  return (
    <FusionDiagramFigure>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        fitView
        nodesConnectable={false}
      >
        <Background color="#aaa" gap={16} />
        <Controls  />
      </ReactFlow>
      <FusionDiagramCaption>Fusion Diagram</FusionDiagramCaption>
    </FusionDiagramFigure>
  );
};