import {entity, persistence} from "simpler-state";
import {personaList} from "../persona3fes";
import {PersonaName} from "../persona3fes.entity";
import {SkillSelection} from "./skillSelection.entity";

export const skillSelectionEntity = entity<SkillSelection>({}, [persistence("skillSelection")]);

export const setSkillSelection = (skillNames: (keyof SkillSelection)[]) => skillSelectionEntity.set(Object.fromEntries(skillNames.map(skillName => [skillName, false])))

export const toggleSkill = (skillName: (keyof SkillSelection)) =>
	skillSelectionEntity.set(skills => ({...skills, [skillName]: !skills[skillName]}));

export const toggleSkillBuilder = (skillName: keyof SkillSelection) => () => toggleSkill(skillName);

export const personaHasSelectedSkills = (personaName: PersonaName) => {
	if (!Object.values(skillSelectionEntity.get()).some(Boolean)) {
		return true;
	}

	return personaList[personaName].skills?.some(({skill}) => skillSelectionEntity.get()[skill]);
}
