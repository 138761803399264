import {entity, persistence} from "simpler-state";
import {PersonaInventory} from "../persona3fesReverseFusion.entity";
import {personaInventoryEntity} from "./personaInventory.state";

export const fusionForestEntity = entity<PersonaInventory>({}, [persistence('fusionForest')]);

export const selectedFusionTreeEntity = entity<keyof PersonaInventory|''>('', [persistence('selectedFusionTree')]);

export const searchDepthEntity = entity<number>(3, [persistence('maxSearchDepth')]);

export const characterLevelEntity = entity<number>(99, [persistence('characterLevel')]);

export const maxSearchDepth = () => personaInventoryEntity.get().length - 2;

export const selectFusionTree = (personaName: keyof PersonaInventory|'') => {
	if (personaName && !(personaName in fusionForestEntity.get())) {
		throw new Error(`Persona ${personaName} not in fusion result.`);
	}

	selectedFusionTreeEntity.set(personaName);
}

export const setSearchDepthOrBounds = (newSearchDepth: number) => {
	if (newSearchDepth < 1) {
		return searchDepthEntity.set(1);
	}

	searchDepthEntity.set(Math.min(newSearchDepth, maxSearchDepth()));
}
