import {FusionDiagram} from './components/fusionDiagram/FusionDiagram';
import {FusionResultList} from './components/fusionResultList/FusionResultList';
import {PersonaInventoryList} from './components/personaInventoryList/PersonaInventoryList';
import {AppSection, ControlPanel, FullScreenSpace} from './AppStyledComponents';

const App = () => (
  <AppSection>
    <ControlPanel>
      <PersonaInventoryList/>
      <FusionResultList/>
    </ControlPanel>
    <FullScreenSpace>
      <FusionDiagram/>
    </FullScreenSpace>
  </AppSection>
);

export default App;
