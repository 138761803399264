import {entity, persistence} from "simpler-state";
import {PersonaName} from "../persona3fes.entity";

const testCase = {
  currentReal: [
    "Pyro Jack",
    "Inugami",
    "Take-minakata",
    "Narcissus",
    "Titan",
    "Take-mikazuchi",
    "Power",
    "Genbu",
    "Lamia",
    "Sarasvati",
    "Sati",
    "Kusi Mitama"
  ],
  depthThree: [
    "Narcissus",
    "Titan",
    "Take-mikazuchi",
    "Kusi Mitama"
  ]
} as Record<string, PersonaName[]>;

export const personaInventoryEntity = entity<PersonaName[]>([...testCase.currentReal], [persistence('personaInventory')]);

export const addPersonaToInventory = (personaName: PersonaName) =>
	personaInventoryEntity.set(inventory => [...new Set([...inventory, personaName])]);

export const removePersonaFromInventory = (personaName: PersonaName) =>
	personaInventoryEntity.set(inventory => inventory.filter(personaInInventory => personaInInventory !== personaName));
