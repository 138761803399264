import {useEffect, useMemo} from "react";
import {PersonaName} from "../../persona3fes.entity";
import {fusionForestEntity, selectFusionTree} from "../../state/fusionTree.state";
import {personaHasSelectedSkills, setSkillSelection, skillSelectionEntity, toggleSkillBuilder} from "../../state/skillSelection.state";

import {SkillNameSection, FusionResultSection, SkillSelectionSection} from "./FusionResultListStyledComponents";

export const FusionResultList = () => {
  const fusionForest = fusionForestEntity.use();
  const skillSelection = skillSelectionEntity.use();

  const uniqueSkillNames = useMemo(() => [
    ...new Set(
      Object.values(fusionForest)
        .flatMap(({skills}) => skills)
        .filter(Boolean)
        .sort((skillA, skillB) => skillB!.level - skillA!.level)
        .map(skill => skill!.skill)
    )
  ], [fusionForest]);
  const fusionResultSkillNames = useMemo(
    () => uniqueSkillNames.map(skill =>
      <SkillNameSection>
        <input checked={skillSelection[skill] ?? false} type="checkbox" key={skill} onChange={toggleSkillBuilder(skill)}/>
        <label onClick={toggleSkillBuilder(skill)}>{skill}</label>
      </SkillNameSection>
    ),
    [uniqueSkillNames, skillSelection]
  );
  const fusionResultNames = useMemo(
    () => Object.values(fusionForest)
      .sort((personaA, personaB) => personaB.level - personaA.level)
      .map(({name: personaFusionResultName}) =>
        <button key={personaFusionResultName} disabled={!personaHasSelectedSkills(personaFusionResultName as PersonaName)} onClick={() => selectFusionTree(personaFusionResultName as PersonaName)}>
          {personaFusionResultName}
        </button>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- skillSelection is part of the personaHasSelectedSkills function
    [fusionForest, skillSelection]
  )

  useEffect(() => setSkillSelection(uniqueSkillNames), [uniqueSkillNames]);

  return <FusionResultSection>
    <section>{fusionResultNames}</section>
    <SkillSelectionSection>{fusionResultSkillNames}</SkillSelectionSection>
  </FusionResultSection>;
}
