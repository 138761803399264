import styled, {css} from 'styled-components';


export const SkillDetails = styled.details`
  overflow: hidden;

  & > summary::marker {
    content: "▼";
    
    ${({isOpen}: {isOpen: boolean;}) => isOpen && css`
      content: "▲";
    `}
  }

`;

export const SkillTable = styled.section`
  width: 100%;
  max-height: 0px;
  transition: max-height 0.4s;
`;

export const PersonaNodeArea = styled.article`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

export const PersonaNodeHeader = styled.header`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;

  & > div:first-child {
    flex: 1 1 auto;
  }
  & > div:last-child {
    flex: 0 0;
  }
`;
