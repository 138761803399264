// Derived from:
//  https://github.com/arantius/persona-fusion-calculator
//  http://www.gamefaqs.com/ps2/937269-shin-megami-tensei-persona-3-fes/faqs/52659
//  http://www.gamefaqs.com/ps2/937269-shin-megami-tensei-persona-3-fes/faqs/53404
//  http://www.gamefaqs.com/ps2/937269-shin-megami-tensei-persona-3-fes/faqs/52531

import {PersonaAttributes, PersonaName} from "./persona3fes.entity";
import personaSkillList from "./personaSkillList.json";

export const personaList: Record<PersonaName, PersonaAttributes> = {
	'Orpheus': {'name': 'Orpheus', 'level': 1, 'arcana': 'Fool', 'special': true, id: 'Orpheus'},
	'Slime': {'name': 'Slime', 'level': 12, 'arcana': 'Fool', id: 'Slime'},
	'Legion': {'name': 'Legion', 'level': 22, 'arcana': 'Fool', id: 'Legion'},
	'Black Frost': {'name': 'Black Frost', 'level': 34, 'arcana': 'Fool', 'special': true, id: 'Black Frost'},
	'Ose': {'name': 'Ose', 'level': 44, 'arcana': 'Fool', id: 'Ose'},
	'Decarabia': {'name': 'Decarabia', 'level': 50, 'arcana': 'Fool', id: 'Decarabia'},
	'Loki': {'name': 'Loki', 'level': 58, 'arcana': 'Fool', id: 'Loki'},
	'Susano-o': {'name': 'Susano-o', 'level': 76, 'arcana': 'Fool', 'max': true, 'special': true, id: 'Susano-o'},
	'Orpheus Telos': {'name': 'Orpheus Telos', 'level': 90, 'arcana': 'Fool', 'special': true, id: 'Orpheus Telos'},
	'Nekomata': {'name': 'Nekomata', 'level': 5, 'arcana': 'Magician', id: 'Nekomata'},
	'Jack Frost': {'name': 'Jack Frost', 'level': 8, 'arcana': 'Magician', id: 'Jack Frost'},
	'Pyro Jack': {'name': 'Pyro Jack', 'level': 14, 'arcana': 'Magician', id: 'Pyro Jack'},
	'Hua Po': {'name': 'Hua Po', 'level': 20, 'arcana': 'Magician', 'item': true, id: 'Hua Po'},
	'Sati': {'name': 'Sati', 'level': 28, 'arcana': 'Magician', id: 'Sati'},
	'Orobas': {'name': 'Orobas', 'level': 34, 'arcana': 'Magician', id: 'Orobas'},
	'Rangda': {'name': 'Rangda', 'level': 40, 'arcana': 'Magician', id: 'Rangda'},
	'Surt': {'name': 'Surt', 'level': 52, 'arcana': 'Magician', 'max': true, id: 'Surt'},
	'Apsaras': {'name': 'Apsaras', 'level': 3, 'arcana': 'Priestess', id: 'Apsaras'},
	'Unicorn': {'name': 'Unicorn', 'level': 11, 'arcana': 'Priestess', id: 'Unicorn'},
	'High Pixie': {'name': 'High Pixie', 'level': 21, 'arcana': 'Priestess', id: 'High Pixie'},
	'Sarasvati': {'name': 'Sarasvati', 'level': 27, 'arcana': 'Priestess', id: 'Sarasvati'},
	'Ganga': {'name': 'Ganga', 'level': 35, 'arcana': 'Priestess', id: 'Ganga'},
	'Parvati': {'name': 'Parvati', 'level': 47, 'arcana': 'Priestess', id: 'Parvati'},
	'Kikuri-hime': {'name': 'Kikuri-hime', 'level': 53, 'arcana': 'Priestess', id: 'Kikuri-hime'},
	'Scathach': {'name': 'Scathach', 'level': 64, 'arcana': 'Priestess', 'max': true, id: 'Scathach'},
	'Leanan Sidhe': {'name': 'Leanan Sidhe', 'level': 33, 'arcana': 'Empress', id: 'Leanan Sidhe'},
	'Yaksini': {'name': 'Yaksini', 'level': 50, 'arcana': 'Empress', id: 'Yaksini'},
	'Laksmi': {'name': 'Laksmi', 'level': 57, 'arcana': 'Empress', id: 'Laksmi'},
	'Hariti': {'name': 'Hariti', 'level': 62, 'arcana': 'Empress', id: 'Hariti'},
	'Gabriel': {'name': 'Gabriel', 'level': 69, 'arcana': 'Empress', id: 'Gabriel'},
	'Mother Harlot': {'name': 'Mother Harlot', 'level': 74, 'arcana': 'Empress', id: 'Mother Harlot'},
	'Skadi': {'name': 'Skadi', 'level': 80, 'arcana': 'Empress', id: 'Skadi'},
	'Alilat': {'name': 'Alilat', 'level': 84, 'arcana': 'Empress', 'max': true, id: 'Alilat'},
	'Forneus': {'name': 'Forneus', 'level': 7, 'arcana': 'Emperor', id: 'Forneus'},
	'Oberon': {'name': 'Oberon', 'level': 15, 'arcana': 'Emperor', id: 'Oberon'},
	'Take-mikazuchi': {'name': 'Take-mikazuchi', 'level': 24, 'arcana': 'Emperor', id: 'Take-mikazuchi'},
	'King Frost': {'name': 'King Frost', 'level': 30, 'arcana': 'Emperor', 'item': true, id: 'King Frost'},
	'Raja Naga': {'name': 'Raja Naga', 'level': 36, 'arcana': 'Emperor', id: 'Raja Naga'},
	'Kingu': {'name': 'Kingu', 'level': 46, 'arcana': 'Emperor', id: 'Kingu'},
	'Barong': {'name': 'Barong', 'level': 52, 'arcana': 'Emperor', id: 'Barong'},
	'Odin': {'name': 'Odin', 'level': 57, 'arcana': 'Emperor', 'max': true, id: 'Odin'},
	'Omoikane': {'name': 'Omoikane', 'level': 7, 'arcana': 'Hierophant', id: 'Omoikane'},
	'Berith': {'name': 'Berith', 'level': 13, 'arcana': 'Hierophant', id: 'Berith'},
	'Shiisaa': {'name': 'Shiisaa', 'level': 26, 'arcana': 'Hierophant', id: 'Shiisaa'},
	'Flauros': {'name': 'Flauros', 'level': 33, 'arcana': 'Hierophant', id: 'Flauros'},
	'Thoth': {'name': 'Thoth', 'level': 41, 'arcana': 'Hierophant', 'item': true, id: 'Thoth'},
	'Hokuto Seikun': {'name': 'Hokuto Seikun', 'level': 47, 'arcana': 'Hierophant', id: 'Hokuto Seikun'},
	'Daisoujou': {'name': 'Daisoujou', 'level': 53, 'arcana': 'Hierophant', 'special': true, id: 'Daisoujou'},
	'Kohryu': {'name': 'Kohryu', 'level': 66, 'arcana': 'Hierophant', 'max': true, 'special': true, id: 'Kohryu'},
	'Pixie': {'name': 'Pixie', 'level': 2, 'arcana': 'Lovers', id: 'Pixie'},
	'Alp': {'name': 'Alp', 'level': 6, 'arcana': 'Lovers', id: 'Alp'},
	'Narcissus': {'name': 'Narcissus', 'level': 20, 'arcana': 'Lovers', id: 'Narcissus'},
	'Queen Mab': {'name': 'Queen Mab', 'level': 27, 'arcana': 'Lovers', id: 'Queen Mab'},
	'Saki Mitama': {'name': 'Saki Mitama', 'level': 39, 'arcana': 'Lovers', id: 'Saki Mitama'},
	'Titania': {'name': 'Titania', 'level': 48, 'arcana': 'Lovers', id: 'Titania'},
	'Raphael': {'name': 'Raphael', 'level': 61, 'arcana': 'Lovers', id: 'Raphael'},
	'Cybele': {'name': 'Cybele', 'level': 68, 'arcana': 'Lovers', 'max': true, id: 'Cybele'},
	'Ara Mitama': {'name': 'Ara Mitama', 'level': 6, 'arcana': 'Chariot', id: 'Ara Mitama'},
	'Chimera': {'name': 'Chimera', 'level': 9, 'arcana': 'Chariot', id: 'Chimera'},
	'Zouchouten': {'name': 'Zouchouten', 'level': 14, 'arcana': 'Chariot', id: 'Zouchouten'},
	'Ares': {'name': 'Ares', 'level': 19, 'arcana': 'Chariot', id: 'Ares'},
	'Oumitsunu': {'name': 'Oumitsunu', 'level': 30, 'arcana': 'Chariot', id: 'Oumitsunu'},
	'Nata Taishi': {'name': 'Nata Taishi', 'level': 37, 'arcana': 'Chariot', 'item': true, id: 'Nata Taishi'},
	'Koumokuten': {'name': 'Koumokuten', 'level': 43, 'arcana': 'Chariot', id: 'Koumokuten'},
	'Thor': {'name': 'Thor', 'level': 53, 'arcana': 'Chariot', 'max': true, id: 'Thor'},
	'Angel': {'name': 'Angel', 'level': 4, 'arcana': 'Justice', id: 'Angel'},
	'Archangel': {'name': 'Archangel', 'level': 10, 'arcana': 'Justice', id: 'Archangel'},
	'Principality': {'name': 'Principality', 'level': 16, 'arcana': 'Justice', id: 'Principality'},
	'Power': {'name': 'Power', 'level': 25, 'arcana': 'Justice', id: 'Power'},
	'Virtue': {'name': 'Virtue', 'level': 32, 'arcana': 'Justice', id: 'Virtue'},
	'Dominion': {'name': 'Dominion', 'level': 42, 'arcana': 'Justice', id: 'Dominion'},
	'Throne': {'name': 'Throne', 'level': 51, 'arcana': 'Justice', id: 'Throne'},
	'Melchizedek': {'name': 'Melchizedek', 'level': 59, 'arcana': 'Justice', 'max': true, id: 'Melchizedek'},
	'Yomotsu Shikome': {'name': 'Yomotsu Shikome', 'level': 9, 'arcana': 'Hermit', id: 'Yomotsu Shikome'},
	'Naga': {'name': 'Naga', 'level': 17, 'arcana': 'Hermit', id: 'Naga'},
	'Lamia': {'name': 'Lamia', 'level': 25, 'arcana': 'Hermit', id: 'Lamia'},
	'Mothman': {'name': 'Mothman', 'level': 32, 'arcana': 'Hermit', id: 'Mothman'},
	'Taraka': {'name': 'Taraka', 'level': 38, 'arcana': 'Hermit', id: 'Taraka'},
	'Kurama Tengu': {'name': 'Kurama Tengu', 'level': 44, 'arcana': 'Hermit', id: 'Kurama Tengu'},
	'Nebiros': {'name': 'Nebiros', 'level': 50, 'arcana': 'Hermit', 'item': true, id: 'Nebiros'},
	'Kumbhanda': {'name': 'Kumbhanda', 'level': 56, 'arcana': 'Hermit', id: 'Kumbhanda'},
	'Arahabaki': {'name': 'Arahabaki', 'level': 60, 'arcana': 'Hermit', 'max': true, 'special': true, id: 'Arahabaki'},
	'Fortuna': {'name': 'Fortuna', 'level': 17, 'arcana': 'Fortune', id: 'Fortuna'},
	'Empusa': {'name': 'Empusa', 'level': 23, 'arcana': 'Fortune', 'item': true, id: 'Empusa'},
	'Kusi Mitama': {'name': 'Kusi Mitama', 'level': 29, 'arcana': 'Fortune', id: 'Kusi Mitama'},
	'Clotho': {'name': 'Clotho', 'level': 38, 'arcana': 'Fortune', id: 'Clotho'},
	'Lachesis': {'name': 'Lachesis', 'level': 45, 'arcana': 'Fortune', id: 'Lachesis'},
	'Atropos': {'name': 'Atropos', 'level': 54, 'arcana': 'Fortune', id: 'Atropos'},
	'Norn': {'name': 'Norn', 'level': 62, 'arcana': 'Fortune', 'max': true, 'special': true, id: 'Norn'},
	'Valkyrie': {'name': 'Valkyrie', 'level': 11, 'arcana': 'Strength', id: 'Valkyrie'},
	'Rakshasa': {'name': 'Rakshasa', 'level': 16, 'arcana': 'Strength', id: 'Rakshasa'},
	'Titan': {'name': 'Titan', 'level': 23, 'arcana': 'Strength', id: 'Titan'},
	'Jikokuten': {'name': 'Jikokuten', 'level': 29, 'arcana': 'Strength', id: 'Jikokuten'},
	'Hanuman': {'name': 'Hanuman', 'level': 37, 'arcana': 'Strength', id: 'Hanuman'},
	'Narasimha': {'name': 'Narasimha', 'level': 46, 'arcana': 'Strength', id: 'Narasimha'},
	'Kali': {'name': 'Kali', 'level': 55, 'arcana': 'Strength', id: 'Kali'},
	'Siegfried': {'name': 'Siegfried', 'level': 59, 'arcana': 'Strength', 'max': true, id: 'Siegfried'},
	'Inugami': {'name': 'Inugami', 'level': 10, 'arcana': 'Hanged Man', id: 'Inugami'},
	'Take-minakata': {'name': 'Take-minakata', 'level': 21, 'arcana': 'Hanged Man', id: 'Take-minakata'},
	'Orthrus': {'name': 'Orthrus', 'level': 28, 'arcana': 'Hanged Man', id: 'Orthrus'},
	'Vasuki': {'name': 'Vasuki', 'level': 38, 'arcana': 'Hanged Man', id: 'Vasuki'},
	'Ubelluris': {'name': 'Ubelluris', 'level': 48, 'arcana': 'Hanged Man', id: 'Ubelluris'},
	'Hecatoncheires': {'name': 'Hecatoncheires', 'level': 54, 'arcana': 'Hanged Man', id: 'Hecatoncheires'},
	'Hell Biker': {'name': 'Hell Biker', 'level': 60, 'arcana': 'Hanged Man', 'item': true, id: 'Hell Biker'},
	'Attis': {'name': 'Attis', 'level': 67, 'arcana': 'Hanged Man', 'max': true, 'special': true, id: 'Attis'},
	'Ghoul': {'name': 'Ghoul', 'level': 18, 'arcana': 'Death', id: 'Ghoul'},
	'Pale Rider': {'name': 'Pale Rider', 'level': 24, 'arcana': 'Death', 'item': true, id: 'Pale Rider'},
	'Loa': {'name': 'Loa', 'level': 31, 'arcana': 'Death', id: 'Loa'},
	'Samael': {'name': 'Samael', 'level': 37, 'arcana': 'Death', id: 'Samael'},
	'Mot': {'name': 'Mot', 'level': 45, 'arcana': 'Death', id: 'Mot'},
	'Alice': {'name': 'Alice', 'level': 56, 'arcana': 'Death', 'special': true, id: 'Alice'},
	'Thanatos': {'name': 'Thanatos', 'level': 64, 'arcana': 'Death', 'max': true, 'special': true, id: 'Thanatos'},
	'Nigi Mitama': {'name': 'Nigi Mitama', 'level': 12, 'arcana': 'Temperance', id: 'Nigi Mitama'},
	'Mithra': {'name': 'Mithra', 'level': 22, 'arcana': 'Temperance', id: 'Mithra'},
	'Genbu': {'name': 'Genbu', 'level': 29, 'arcana': 'Temperance', id: 'Genbu'},
	'Seiryuu': {'name': 'Seiryuu', 'level': 36, 'arcana': 'Temperance', id: 'Seiryuu'},
	'Okuninushi': {'name': 'Okuninushi', 'level': 44, 'arcana': 'Temperance', id: 'Okuninushi'},
	'Suzaku': {'name': 'Suzaku', 'level': 51, 'arcana': 'Temperance', id: 'Suzaku'},
	'Byakko': {'name': 'Byakko', 'level': 57, 'arcana': 'Temperance', id: 'Byakko'},
	'Yurlungur': {'name': 'Yurlungur', 'level': 64, 'arcana': 'Temperance', 'max': true, id: 'Yurlungur'},
	'Lilim': {'name': 'Lilim', 'level': 8, 'arcana': 'Devil', id: 'Lilim'},
	'Vetala': {'name': 'Vetala', 'level': 24, 'arcana': 'Devil', id: 'Vetala'},
	'Incubus': {'name': 'Incubus', 'level': 34, 'arcana': 'Devil', id: 'Incubus'},
	'Succubus': {'name': 'Succubus', 'level': 43, 'arcana': 'Devil', id: 'Succubus'},
	'Pazuzu': {'name': 'Pazuzu', 'level': 52, 'arcana': 'Devil', id: 'Pazuzu'},
	'Lilith': {'name': 'Lilith', 'level': 61, 'arcana': 'Devil', 'item': true, 'special': true, id: 'Lilith'},
	'Abaddon': {'name': 'Abaddon', 'level': 68, 'arcana': 'Devil', id: 'Abaddon'},
	'Beelzebub': {'name': 'Beelzebub', 'level': 81, 'arcana': 'Devil', 'max': true, 'special': true, id: 'Beelzebub'},
	'Eligor': {'name': 'Eligor', 'level': 31, 'arcana': 'Tower', id: 'Eligor'},
	'Cu Chulainn': {'name': 'Cu Chulainn', 'level': 40, 'arcana': 'Tower', id: 'Cu Chulainn'},
	'Bishamonten': {'name': 'Bishamonten', 'level': 60, 'arcana': 'Tower', id: 'Bishamonten'},
	'Seiten Taisei': {'name': 'Seiten Taisei', 'level': 67, 'arcana': 'Tower', id: 'Seiten Taisei'},
	'Masakado': {'name': 'Masakado', 'level': 73, 'arcana': 'Tower', 'item': true, 'special': true, id: 'Masakado'},
	'Mara': {'name': 'Mara', 'level': 77, 'arcana': 'Tower', 'special': true, id: 'Mara'},
	'Shiva': {'name': 'Shiva', 'level': 82, 'arcana': 'Tower', 'special': true, id: 'Shiva'},
	'Chi You': {'name': 'Chi You', 'level': 86, 'arcana': 'Tower', 'max': true, id: 'Chi You'},
	'Nandi': {'name': 'Nandi', 'level': 39, 'arcana': 'Star', id: 'Nandi'},
	'Kaiwan': {'name': 'Kaiwan', 'level': 49, 'arcana': 'Star', id: 'Kaiwan'},
	'Ganesha': {'name': 'Ganesha', 'level': 58, 'arcana': 'Star', id: 'Ganesha'},
	'Garuda': {'name': 'Garuda', 'level': 65, 'arcana': 'Star', id: 'Garuda'},
	'Kartikeya': {'name': 'Kartikeya', 'level': 70, 'arcana': 'Star', 'item': true, id: 'Kartikeya'},
	'Saturnus': {'name': 'Saturnus', 'level': 78, 'arcana': 'Star', id: 'Saturnus'},
	'Helel': {'name': 'Helel', 'level': 88, 'arcana': 'Star', 'max': true, id: 'Helel'},
	'Gurr': {'name': 'Gurr', 'level': 15, 'arcana': 'Moon', id: 'Gurr'},
	'Yamatano-orochi': {'name': 'Yamatano-orochi', 'level': 26, 'arcana': 'Moon', id: 'Yamatano-orochi'},
	'Girimehkala': {'name': 'Girimehkala', 'level': 42, 'arcana': 'Moon', 'special': true, id: 'Girimehkala'},
	'Dionysus': {'name': 'Dionysus', 'level': 48, 'arcana': 'Moon', id: 'Dionysus'},
	'Chernobog': {'name': 'Chernobog', 'level': 58, 'arcana': 'Moon', id: 'Chernobog'},
	'Seth': {'name': 'Seth', 'level': 66, 'arcana': 'Moon', id: 'Seth'},
	'Baal Zebul': {'name': 'Baal Zebul', 'level': 71, 'arcana': 'Moon', id: 'Baal Zebul'},
	'Sandalphon': {'name': 'Sandalphon', 'level': 74, 'arcana': 'Moon', 'max': true, 'special': true, id: 'Sandalphon'},
	'Yatagarasu': {'name': 'Yatagarasu', 'level': 30, 'arcana': 'Sun', id: 'Yatagarasu'},
	'Quetzalcoatl': {'name': 'Quetzalcoatl', 'level': 43, 'arcana': 'Sun', id: 'Quetzalcoatl'},
	'Jatayu': {'name': 'Jatayu', 'level': 55, 'arcana': 'Sun', id: 'Jatayu'},
	'Horus': {'name': 'Horus', 'level': 63, 'arcana': 'Sun', id: 'Horus'},
	'Suparna': {'name': 'Suparna', 'level': 70, 'arcana': 'Sun', id: 'Suparna'},
	'Vishnu': {'name': 'Vishnu', 'level': 78, 'arcana': 'Sun', id: 'Vishnu'},
	'Asura': {'name': 'Asura', 'level': 85, 'arcana': 'Sun', 'max': true, 'special': true, id: 'Asura'},
	'Anubis': {'name': 'Anubis', 'level': 59, 'arcana': 'Judgment', id: 'Anubis'},
	'Trumpeter': {'name': 'Trumpeter', 'level': 65, 'arcana': 'Judgment', id: 'Trumpeter'},
	'Michael': {'name': 'Michael', 'level': 72, 'arcana': 'Judgment', id: 'Michael'},
	'Satan': {'name': 'Satan', 'level': 79, 'arcana': 'Judgment', id: 'Satan'},
	'Lucifer': {'name': 'Lucifer', 'level': 89, 'arcana': 'Judgment', 'special': true, id: 'Lucifer'},
	'Messiah': {'name': 'Messiah', 'level': 90, 'arcana': 'Judgment', 'max': true, 'special': true, id: 'Messiah'},
	'Uriel': {'name': 'Uriel', 'level': 63, 'arcana': 'Aeon', id: 'Uriel'},
	'Nidhoggr': {'name': 'Nidhoggr', 'level': 69, 'arcana': 'Aeon', id: 'Nidhoggr'},
	'Ananta': {'name': 'Ananta', 'level': 75, 'arcana': 'Aeon', id: 'Ananta'},
	'Atavaka': {'name': 'Atavaka', 'level': 80, 'arcana': 'Aeon', id: 'Atavaka'},
	'Metatron': {'name': 'Metatron', 'level': 87, 'arcana': 'Aeon', 'max': true, 'special': true, id: 'Metatron'},
};
Object.entries(personaList).forEach(([personaName, persona]) => (persona.skills = personaSkillList[personaName as keyof typeof personaSkillList].sort((skillA, skillB) => skillA.level - skillB.level)));

export const personaNameListByArcana = Object.entries(personaList).reduce(
	(arcanaRecord, [personaName, {arcana}]) => ({
		...arcanaRecord,
		[arcana]: [
			...(arcanaRecord[arcana] ?? []),
			personaName as PersonaName
		]
	}),
	{} as Record<string, PersonaName[]>
);
export const arcanaRank = [
	...new Set(
		Object.values(personaList).map(({arcana}) => arcana)
	)
];

export const dualFusionArcanaResults: Record<string, string> = {
	'Fool+Fool': 'Fool',
	'Fool+Magician': 'Hierophant',
	'Fool+Priestess': 'Justice',
	'Fool+Empress': 'Fortune',
	'Fool+Emperor': 'Chariot',
	'Fool+Hierophant': 'Hermit',
	'Fool+Lovers': 'Priestess',
	'Fool+Chariot': 'Emperor',
	'Fool+Justice': 'Lovers',
	'Fool+Hermit': 'Priestess',
	'Fool+Fortune': 'Justice',
	'Fool+Strength': 'Hanged Man',
	'Fool+Hanged Man': 'Magician',
	'Fool+Death': 'Strength',
	'Fool+Temperance': 'Hierophant',
	'Fool+Devil': 'Hermit',
	'Fool+Tower': 'Moon',
	'Fool+Star': 'Aeon',
	'Fool+Moon': 'Fortune',
	'Fool+Sun': 'Empress',
	'Fool+Judgment': 'Star',
	'Fool+Aeon': 'Death',
	'Magician+Magician': 'Magician',
	'Magician+Priestess': 'Lovers',
	'Magician+Empress': 'Hanged Man',
	'Magician+Emperor': 'Temperance',
	'Magician+Hierophant': 'Hermit',
	'Magician+Lovers': 'Emperor',
	'Magician+Chariot': 'Devil',
	'Magician+Justice': 'Hierophant',
	'Magician+Hermit': 'Chariot',
	'Magician+Fortune': 'Emperor',
	'Magician+Hanged Man': 'Devil',
	'Magician+Temperance': 'Death',
	'Magician+Devil': 'Temperance',
	'Magician+Tower': 'Empress',
	'Magician+Star': 'Empress',
	'Magician+Moon': 'Priestess',
	'Magician+Sun': 'Lovers',
	'Priestess+Priestess': 'Priestess',
	'Priestess+Empress': 'Lovers',
	'Priestess+Emperor': 'Justice',
	'Priestess+Hierophant': 'Chariot',
	'Priestess+Lovers': 'Magician',
	'Priestess+Chariot': 'Magician',
	'Priestess+Justice': 'Lovers',
	'Priestess+Hermit': 'Strength',
	'Priestess+Fortune': 'Magician',
	'Priestess+Strength': 'Hermit',
	'Priestess+Hanged Man': 'Strength',
	'Priestess+Death': 'Emperor',
	'Priestess+Temperance': 'Empress',
	'Priestess+Star': 'Justice',
	'Priestess+Moon': 'Star',
	'Priestess+Sun': 'Star',
	'Priestess+Judgment': 'Empress',
	'Priestess+Aeon': 'Empress',
	'Empress+Empress': 'Empress',
	'Empress+Emperor': 'Lovers',
	'Empress+Hierophant': 'Priestess',
	'Empress+Lovers': 'Fortune',
	'Empress+Chariot': 'Devil',
	'Empress+Justice': 'Emperor',
	'Empress+Hermit': 'Lovers',
	'Empress+Fortune': 'Strength',
	'Empress+Strength': 'Chariot',
	'Empress+Hanged Man': 'Chariot',
	'Empress+Death': 'Devil',
	'Empress+Temperance': 'Lovers',
	'Empress+Devil': 'Lovers',
	'Empress+Tower': 'Chariot',
	'Empress+Star': 'Temperance',
	'Empress+Moon': 'Lovers',
	'Empress+Sun': 'Lovers',
	'Empress+Aeon': 'Moon',
	'Emperor+Emperor': 'Emperor',
	'Emperor+Hierophant': 'Chariot',
	'Emperor+Lovers': 'Chariot',
	'Emperor+Chariot': 'Hermit',
	'Emperor+Justice': 'Devil',
	'Emperor+Hermit': 'Strength',
	'Emperor+Strength': 'Hanged Man',
	'Emperor+Hanged Man': 'Hermit',
	'Emperor+Death': 'Moon',
	'Emperor+Temperance': 'Hanged Man',
	'Emperor+Star': 'Justice',
	'Emperor+Sun': 'Empress',
	'Emperor+Judgment': 'Hierophant',
	'Hierophant+Hierophant': 'Hierophant',
	'Hierophant+Lovers': 'Magician',
	'Hierophant+Chariot': 'Justice',
	'Hierophant+Justice': 'Chariot',
	'Hierophant+Hermit': 'Chariot',
	'Hierophant+Fortune': 'Emperor',
	'Hierophant+Strength': 'Priestess',
	'Hierophant+Hanged Man': 'Lovers',
	'Hierophant+Death': 'Empress',
	'Hierophant+Temperance': 'Strength',
	'Hierophant+Tower': 'Temperance',
	'Hierophant+Star': 'Priestess',
	'Hierophant+Moon': 'Temperance',
	'Hierophant+Sun': 'Temperance',
	'Hierophant+Judgment': 'Lovers',
	'Lovers+Lovers': 'Lovers',
	'Lovers+Chariot': 'Emperor',
	'Lovers+Justice': 'Chariot',
	'Lovers+Hermit': 'Justice',
	'Lovers+Fortune': 'Magician',
	'Lovers+Strength': 'Hierophant',
	'Lovers+Hanged Man': 'Hermit',
	'Lovers+Death': 'Devil',
	'Lovers+Temperance': 'Priestess',
	'Lovers+Devil': 'Strength',
	'Lovers+Tower': 'Star',
	'Lovers+Star': 'Hierophant',
	'Lovers+Moon': 'Empress',
	'Lovers+Sun': 'Hierophant',
	'Lovers+Aeon': 'Hanged Man',
	'Chariot+Chariot': 'Chariot',
	'Chariot+Justice': 'Magician',
	'Chariot+Hermit': 'Temperance',
	'Chariot+Fortune': 'Strength',
	'Chariot+Strength': 'Justice',
	'Chariot+Hanged Man': 'Fortune',
	'Chariot+Temperance': 'Death',
	'Chariot+Devil': 'Hanged Man',
	'Chariot+Tower': 'Moon',
	'Chariot+Moon': 'Fortune',
	'Chariot+Aeon': 'Death',
	'Justice+Justice': 'Justice',
	'Justice+Hermit': 'Priestess',
	'Justice+Fortune': 'Chariot',
	'Justice+Strength': 'Temperance',
	'Justice+Hanged Man': 'Priestess',
	'Justice+Death': 'Moon',
	'Justice+Temperance': 'Moon',
	'Justice+Tower': 'Star',
	'Justice+Star': 'Emperor',
	'Justice+Sun': 'Emperor',
	'Justice+Judgment': 'Aeon',
	'Hermit+Hermit': 'Hermit',
	'Hermit+Fortune': 'Emperor',
	'Hermit+Strength': 'Fortune',
	'Hermit+Hanged Man': 'Fortune',
	'Hermit+Temperance': 'Hanged Man',
	'Hermit+Devil': 'Death',
	'Hermit+Star': 'Chariot',
	'Hermit+Moon': 'Magician',
	'Hermit+Aeon': 'Star',
	'Fortune+Fortune': 'Fortune',
	'Fortune+Hanged Man': 'Strength',
	'Fortune+Temperance': 'Lovers',
	'Fortune+Devil': 'Moon',
	'Fortune+Tower': 'Moon',
	'Fortune+Star': 'Moon',
	'Fortune+Moon': 'Chariot',
	'Fortune+Sun': 'Temperance',
	'Fortune+Aeon': 'Devil',
	'Strength+Strength': 'Strength',
	'Strength+Hanged Man': 'Hermit',
	'Strength+Death': 'Hanged Man',
	'Strength+Temperance': 'Moon',
	'Strength+Devil': 'Fortune',
	'Strength+Tower': 'Devil',
	'Strength+Star': 'Priestess',
	'Strength+Moon': 'Hanged Man',
	'Strength+Sun': 'Priestess',
	'Strength+Judgment': 'Hanged Man',
	'Hanged Man+Hanged Man': 'Hanged Man',
	'Hanged Man+Death': 'Devil',
	'Hanged Man+Temperance': 'Hierophant',
	'Hanged Man+Devil': 'Moon',
	'Hanged Man+Tower': 'Death',
	'Hanged Man+Star': 'Strength',
	'Hanged Man+Moon': 'Empress',
	'Hanged Man+Aeon': 'Temperance',
	'Death+Death': 'Death',
	'Death+Moon': 'Star',
	'Temperance+Temperance': 'Temperance',
	'Temperance+Devil': 'Death',
	'Temperance+Tower': 'Devil',
	'Temperance+Star': 'Moon',
	'Temperance+Moon': 'Empress',
	'Temperance+Sun': 'Star',
	'Temperance+Judgment': 'Moon',
	'Temperance+Aeon': 'Star',
	'Devil+Devil': 'Devil',
	'Devil+Aeon': 'Lovers',
	'Tower+Tower': 'Tower',
	'Tower+Moon': 'Fortune',
	'Tower+Judgment': 'Aeon',
	'Tower+Aeon': 'Moon',
	'Star+Star': 'Star',
	'Star+Moon': 'Death',
	'Star+Sun': 'Justice',
	'Star+Judgment': 'Temperance',
	'Star+Aeon': 'Devil',
	'Moon+Moon': 'Moon',
	'Moon+Sun': 'Temperance',
	'Sun+Sun': 'Sun',
	'Sun+Judgment': 'Star',
	'Sun+Aeon': 'Empress',
	'Judgment+Judgment': 'Judgment',
	'Judgment+Aeon': 'Star',
	'Aeon+Aeon': 'Aeon',
};
Object.entries(dualFusionArcanaResults).forEach(([key, value]) => (
	dualFusionArcanaResults[key.split('+').reverse().join('+')] = value
))

export const triFusionArcanaResults: Record<string, string> = {
	'Fool+Fool': 'Fool',
	'Fool+Magician': 'Emperor',
	'Fool+Priestess': 'Magician',
	'Fool+Empress': 'Fortune',
	'Fool+Emperor': 'Lovers',
	'Fool+Hierophant': 'Hermit',
	'Fool+Lovers': 'Temperance',
	'Fool+Chariot': 'Devil',
	'Fool+Justice': 'Chariot',
	'Fool+Hermit': 'Priestess',
	'Fool+Fortune': 'Justice',
	'Fool+Strength': 'Hanged Man',
	'Fool+Hanged Man': 'Magician',
	'Fool+Death': 'Strength',
	'Fool+Temperance': 'Hierophant',
	'Fool+Devil': 'Justice',
	'Fool+Tower': 'Moon',
	'Fool+Star': 'Aeon',
	'Fool+Moon': 'Fortune',
	'Fool+Sun': 'Empress',
	'Fool+Judgment': 'Star',
	'Fool+Aeon': 'Death',
	'Magician+Magician': 'Magician',
	'Magician+Priestess': 'Devil',
	'Magician+Empress': 'Hanged Man',
	'Magician+Emperor': 'Lovers',
	'Magician+Hierophant': 'Hermit',
	'Magician+Lovers': 'Devil',
	'Magician+Chariot': 'Moon',
	'Magician+Justice': 'Fool',
	'Magician+Hermit': 'Hanged Man',
	'Magician+Fortune': 'Emperor',
	'Magician+Strength': 'Star',
	'Magician+Hanged Man': 'Devil',
	'Magician+Death': 'Tower',
	'Magician+Temperance': 'Death',
	'Magician+Devil': 'Temperance',
	'Magician+Tower': 'Empress',
	'Magician+Star': 'Empress',
	'Magician+Moon': 'Fortune',
	'Magician+Sun': 'Lovers',
	'Magician+Judgment': 'Tower',
	'Magician+Aeon': 'Sun',
	'Priestess+Priestess': 'Priestess',
	'Priestess+Empress': 'Lovers',
	'Priestess+Emperor': 'Hierophant',
	'Priestess+Hierophant': 'Chariot',
	'Priestess+Lovers': 'Hermit',
	'Priestess+Chariot': 'Emperor',
	'Priestess+Justice': 'Hierophant',
	'Priestess+Hermit': 'Fool',
	'Priestess+Fortune': 'Magician',
	'Priestess+Strength': 'Chariot',
	'Priestess+Hanged Man': 'Strength',
	'Priestess+Death': 'Emperor',
	'Priestess+Temperance': 'Empress',
	'Priestess+Devil': 'Tower',
	'Priestess+Tower': 'Death',
	'Priestess+Star': 'Justice',
	'Priestess+Moon': 'Star',
	'Priestess+Sun': 'Star',
	'Priestess+Judgment': 'Justice',
	'Priestess+Aeon': 'Empress',
	'Empress+Empress': 'Empress',
	'Empress+Emperor': 'Fool',
	'Empress+Hierophant': 'Priestess',
	'Empress+Lovers': 'Fortune',
	'Empress+Chariot': 'Devil',
	'Empress+Justice': 'Emperor',
	'Empress+Hermit': 'Lovers',
	'Empress+Fortune': 'Strength',
	'Empress+Strength': 'Chariot',
	'Empress+Hanged Man': 'Chariot',
	'Empress+Death': 'Devil',
	'Empress+Temperance': 'Lovers',
	'Empress+Devil': 'Magician',
	'Empress+Tower': 'Chariot',
	'Empress+Star': 'Temperance',
	'Empress+Moon': 'Lovers',
	'Empress+Sun': 'Lovers',
	'Empress+Judgment': 'Devil',
	'Empress+Aeon': 'Moon',
	'Emperor+Emperor': 'Emperor',
	'Emperor+Hierophant': 'Chariot',
	'Emperor+Lovers': 'Strength',
	'Emperor+Chariot': 'Justice',
	'Emperor+Justice': 'Chariot',
	'Emperor+Hermit': 'Lovers',
	'Emperor+Fortune': 'Sun',
	'Emperor+Strength': 'Hanged Man',
	'Emperor+Hanged Man': 'Temperance',
	'Emperor+Death': 'Moon',
	'Emperor+Temperance': 'Hanged Man',
	'Emperor+Devil': 'Tower',
	'Emperor+Tower': 'Death',
	'Emperor+Star': 'Hermit',
	'Emperor+Moon': 'Tower',
	'Emperor+Sun': 'Empress',
	'Emperor+Judgment': 'Hierophant',
	'Emperor+Aeon': 'Sun',
	'Hierophant+Hierophant': 'Hierophant',
	'Hierophant+Lovers': 'Temperance',
	'Hierophant+Chariot': 'Sun',
	'Hierophant+Justice': 'Magician',
	'Hierophant+Hermit': 'Chariot',
	'Hierophant+Fortune': 'Emperor',
	'Hierophant+Strength': 'Emperor',
	'Hierophant+Hanged Man': 'Fortune',
	'Hierophant+Death': 'Empress',
	'Hierophant+Temperance': 'Strength',
	'Hierophant+Devil': 'Fool',
	'Hierophant+Tower': 'Temperance',
	'Hierophant+Star': 'Priestess',
	'Hierophant+Moon': 'Temperance',
	'Hierophant+Sun': 'Temperance',
	'Hierophant+Judgment': 'Lovers',
	'Hierophant+Aeon': 'Tower',
	'Lovers+Lovers': 'Lovers',
	'Lovers+Chariot': 'Strength',
	'Lovers+Justice': 'Hanged Man',
	'Lovers+Hermit': 'Hierophant',
	'Lovers+Fortune': 'Fool',
	'Lovers+Strength': 'Hierophant',
	'Lovers+Hanged Man': 'Hermit',
	'Lovers+Death': 'Devil',
	'Lovers+Temperance': 'Priestess',
	'Lovers+Devil': 'Death',
	'Lovers+Tower': 'Star',
	'Lovers+Star': 'Hierophant',
	'Lovers+Moon': 'Empress',
	'Lovers+Sun': 'Hierophant',
	'Lovers+Judgment': 'Sun',
	'Lovers+Aeon': 'Hanged Man',
	'Chariot+Chariot': 'Chariot',
	'Chariot+Justice': 'Magician',
	'Chariot+Hermit': 'Hanged Man',
	'Chariot+Fortune': 'Hermit',
	'Chariot+Strength': 'Justice',
	'Chariot+Hanged Man': 'Fortune',
	'Chariot+Death': 'Fool',
	'Chariot+Temperance': 'Death',
	'Chariot+Devil': 'Star',
	'Chariot+Tower': 'Moon',
	'Chariot+Star': 'Sun',
	'Chariot+Moon': 'Fortune',
	'Chariot+Sun': 'Justice',
	'Chariot+Judgment': 'Tower',
	'Chariot+Aeon': 'Death',
	'Justice+Justice': 'Justice',
	'Justice+Hermit': 'Strength',
	'Justice+Fortune': 'Chariot',
	'Justice+Strength': 'Temperance',
	'Justice+Hanged Man': 'Priestess',
	'Justice+Death': 'Moon',
	'Justice+Temperance': 'Moon',
	'Justice+Devil': 'Tower',
	'Justice+Tower': 'Sun',
	'Justice+Star': 'Emperor',
	'Justice+Moon': 'Tower',
	'Justice+Sun': 'Emperor',
	'Justice+Judgment': 'Aeon',
	'Justice+Aeon': 'Judgment',
	'Hermit+Hermit': 'Hermit',
	'Hermit+Fortune': 'Emperor',
	'Hermit+Strength': 'Fortune',
	'Hermit+Hanged Man': 'Fortune',
	'Hermit+Death': 'Tower',
	'Hermit+Temperance': 'Hanged Man',
	'Hermit+Devil': 'Death',
	'Hermit+Tower': 'Death',
	'Hermit+Star': 'Chariot',
	'Hermit+Moon': 'Magician',
	'Hermit+Sun': 'Star',
	'Hermit+Judgment': 'Tower',
	'Hermit+Aeon': 'Star',
	'Fortune+Fortune': 'Fortune',
	'Fortune+Strength': 'Sun',
	'Fortune+Hanged Man': 'Strength',
	'Fortune+Death': 'Judgment',
	'Fortune+Temperance': 'Lovers',
	'Fortune+Devil': 'Hermit',
	'Fortune+Tower': 'Aeon',
	'Fortune+Star': 'Moon',
	'Fortune+Moon': 'Chariot',
	'Fortune+Sun': 'Temperance',
	'Fortune+Judgment': 'Star',
	'Fortune+Aeon': 'Devil',
	'Strength+Hermit': 'Emperor',
	'Strength+Strength': 'Strength',
	'Strength+Hanged Man': 'Fortune',
	'Strength+Death': 'Hanged Man',
	'Strength+Temperance': 'Moon',
	'Strength+Devil': 'Empress',
	'Strength+Tower': 'Judgment',
	'Strength+Star': 'Priestess',
	'Strength+Moon': 'Aeon',
	'Strength+Sun': 'Priestess',
	'Strength+Judgment': 'Hanged Man',
	'Strength+Aeon': 'Tower',
	'Hanged Man+Hanged Man': 'Hanged Man',
	'Hanged Man+Death': 'Devil',
	'Hanged Man+Temperance': 'Hierophant',
	'Hanged Man+Devil': 'Death',
	'Hanged Man+Tower': 'Death',
	'Hanged Man+Star': 'Strength',
	'Hanged Man+Moon': 'Empress',
	'Hanged Man+Sun': 'Judgment',
	'Hanged Man+Judgment': 'Aeon',
	'Hanged Man+Aeon': 'Temperance',
	'Death+Chariot': 'Fool',
	'Death+Death': 'Death',
	'Death+Temperance': 'Tower',
	'Death+Devil': 'Judgment',
	'Death+Tower': 'Sun',
	'Death+Star': 'Tower',
	'Death+Moon': 'Star',
	'Death+Sun': 'Moon',
	'Death+Judgment': 'Fool',
	'Death+Aeon': 'Sun',
	'Temperance+Temperance': 'Temperance',
	'Temperance+Devil': 'Moon',
	'Temperance+Tower': 'Devil',
	'Temperance+Star': 'Hermit',
	'Temperance+Moon': 'Empress',
	'Temperance+Sun': 'Judgment',
	'Temperance+Judgment': 'Justice',
	'Temperance+Aeon': 'Star',
	'Devil+Devil': 'Devil',
	'Devil+Tower': 'Judgment',
	'Devil+Star': 'Magician',
	'Devil+Moon': 'Judgment',
	'Devil+Sun': 'Death',
	'Devil+Judgment': 'Moon',
	'Devil+Aeon': 'Lovers',
	'Tower+Tower': 'Tower',
	'Tower+Star': 'Judgment',
	'Tower+Moon': 'Fortune',
	'Tower+Sun': 'Moon',
	'Tower+Judgment': 'Aeon',
	'Tower+Aeon': 'Fool',
	'Star+Star': 'Star',
	'Star+Moon': 'Sun',
	'Star+Sun': 'Aeon',
	'Star+Judgment': 'Temperance',
	'Star+Aeon': 'Devil',
	'Moon+Moon': 'Moon',
	'Moon+Sun': 'Temperance',
	'Moon+Judgment': 'Priestess',
	'Moon+Aeon': 'Judgment',
	'Sun+Sun': 'Sun',
	'Sun+Judgment': 'Star',
	'Sun+Aeon': 'Empress',
	'Judgment+Judgment': 'Judgment',
	'Judgment+Aeon': 'Fool',
	'Aeon+Aeon': 'Aeon',
};
Object.entries(triFusionArcanaResults).forEach(([key, value]) => (
	triFusionArcanaResults[key.split('+').reverse().join('+')] = value
))

export const specialCombos = [
	{'result': 'Alice', 'sources': ['Pixie', 'Lilim', 'Narcissus', 'Nata Taishi']},
	{'result': 'Arahabaki', 'sources': ['Omoikane', 'Take-minakata', 'Okuninushi', 'Kikuri-hime']},
	{'result': 'Asura', 'sources': ['Yatagarasu', 'Quetzalcoatl', 'Jatayu', 'Horus', 'Suparna', 'Vishnu']},
	{'result': 'Attis', 'sources': ['Inugami', 'Take-minakata', 'Orthrus', 'Vasuki', 'Ubelluris']},
	{'result': 'Beelzebub', 'sources': ['Incubus', 'Succubus', 'Pazuzu', 'Lilith', 'Abaddon', 'Baal Zebul']},
	{'result': 'Black Frost', 'sources': ['Jack Frost', 'Pyro Jack', 'King Frost', 'Queen Mab']},
	{'result': 'Daisoujou', 'sources': ['Mithra', 'Ara Mitama', 'Nigi Mitama', 'Kusi Mitama', 'Saki Mitama']},
	{'result': 'Girimehkala', 'sources': ['Gurr', 'Vetala', 'Taraka', 'Rangda']},
	{'result': 'Kohryu', 'sources': ['Genbu', 'Seiryuu', 'Suzaku', 'Byakko']},
	{'result': 'Lilith', 'sources': ['Lilim', 'Vetala', 'Incubus', 'Succubus']},
	{'result': 'Lucifer', 'sources': ['Samael', 'Abaddon', 'Beelzebub', 'Satan', 'Helel']},
	{'result': 'Mara', 'sources': ['Incubus', 'Pazuzu', 'Mot', 'Kumbhanda', 'Attis']},
	{'result': 'Masakado', 'sources': ['Zouchouten', 'Jikokuten', 'Koumokuten', 'Bishamonten']},
	{'result': 'Messiah', 'sources': ['Orpheus', 'Thanatos']},
	{'result': 'Metatron', 'sources': ['Uriel', 'Raphael', 'Gabriel', 'Michael']},
	{'result': 'Norn', 'sources': ['Clotho', 'Lachesis', 'Atropos']},
	{'result': 'Orpheus', 'sources': ['Slime', 'Legion']},
	{'result': 'Orpheus Telos', 'sources': ['Thanatos', 'Chi You', 'Helel', 'Asura', 'Messiah', 'Metatron']},
	{'result': 'Sandalphon', 'sources': ['Gurr', 'Suzaku', 'Yatagarasu', 'Horus', 'Garuda']},
	{'result': 'Shiva', 'sources': ['Barong', 'Rangda']},
	{'result': 'Susano-o', 'sources': ['Orpheus', 'Legion', 'Black Frost', 'Ose', 'Decarabia', 'Loki']},
	{'result': 'Thanatos', 'sources': ['Ghoul', 'Pale Rider', 'Loa', 'Samael', 'Mot', 'Alice']},
];
