import styled from "styled-components";

export const FusionResultSection = styled.section`
  display: flex;

  & > * {
    display: flex;
    flex-direction: column;
  }
`;
export const SkillSelectionSection = styled.section`
  align-items: flex-start;
`;
export const SkillNameSection = styled.section`
  input[type="checkbox"],
  input[type="checkbox"]+label{
    cursor:pointer;
  }
`;
